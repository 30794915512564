import * as React from "react"
import LayoutBlog from "../components/LayoutBlog"
import BlogHome from "../components/BlogPage/BlogHome"
import BlogCategoryBar from "../components/BlogPage/BlogCategoryBar"
import HeroImageBlog from "../components/BlogPage/HeroImageBlog"

function bloghome({ data }) {
  return (
    <LayoutBlog>
      <HeroImageBlog />
      {/* <BlogCategoryBar/> */}
      <BlogHome/>
    </LayoutBlog>
  )
}

export default bloghome